import {
  CHANNEL_AMBASSADORS,
  CHANNEL_INSTAGRAM,
  CHANNEL_LINKEDIN,
  CHANNEL_LINKEDIN_PROFILE,
  CHANNEL_TWITTER,
  CHANNEL_FACEBOOK,
  CHANNEL_SLACK,
  CHANNEL_GOOGLE_BUSINESS,
  CHANNEL_TIKTOK,
  CHANNEL_PINTEREST,
  FACEBOOK_HASHTAGS_LIMIT,
  CHANNEL_THREADS,
} from '@/destinations/constants/Constants';

import { STATUS_DRAFT } from '@/storychief/constants/Constants';
import StoryChief from '@/storychief';
import getPostsetAttachmentsType from '@/postsets/utils/getPostsetAttachmentsType';
import getInstagramDestinationHasPublishScope from '@/destinations/utils/getInstagramDestinationHasPublishScope';
import getLinkedInDestinationHasPageFollowersScope from '@/destinations/utils/getLinkedInDestinationHasPageFollowersScope';
import getPostAttachmentMedia from '@/posts/utils/getPostAttachmentMedia';
import loadImage from '@/storychief/utils/loadImage';
import { getPostMessageLimitReached, getHashtagLimitReached } from '@/posts/selectors';
import {
  FACEBOOK_VIDEO_TYPES,
  SOCIAL_MAX_SIZES,
  SOCIAL_RECOMMENDED_MIN_SIZES,
} from '@/posts/constants/Constants';
import getTwitterVideoLimitErrors from '@/posts/utils/getTwitterVideoLimitErrors';
import getDestinationTitleLimit from '@/destinations/utils/getDestinationTitleLimit';
import getPostInstagramVideoOptions from '@/posts/utils/getPostInstagramVideoOptions';
import checkInstagramReelsAspectRatio from '@/posts/utils/checkInstagramReelsAspectRatio';
import checkInstagramReelsSize from '@/posts/utils/checkInstagramReelsSize';
import checkInstagramReelsDuration from '@/posts/utils/checkInstagramReelsDuration';
import checkInstagramImageAspectRatio from '@/posts/utils/checkInstagramImageAspectRatio';
import checkInstagramReelsResolution from '@/posts/utils/checkInstagramReelsResolution';
import getPostsetVideoAttachmentLength from '@/postsets/utils/getPostsetVideoAttachmentLength';
import getPostFacebookVideoOptions from '@/posts/utils/getPostFacebookVideoOptions';
import checkFacebookReelsDuration from '@/posts/utils/checkFacebookReelsDuration';
import checkFacebookReelsAspectRatio from '@/posts/utils/checkFacebookReelsAspectRatio';
import checkFacebookVideoResolution from '@/posts/utils/checkFacebookReelsResolution';
import getLinkAttachmentImageUrl from '@/posts/utils/getLinkAttachmentImageUrl';
import getDestinationHashtagLimit from '@/destinations/utils/getDestinationHashtagLimit';
import checkInstagramVideoStoryAspectRatio from '@/posts/utils/checkInstagramVideoStoryAspectRatio';
import checkInstagramVideoStoryDuration from '@/posts/utils/checkInstagramVideoStoryDuration';
import checkInstagramVideoStorySize from '@/posts/utils/checkInstagramVideoStorySize';
import checkInstagramVideoStoryResolution from '@/posts/utils/checkInstagramVideoStoryResolution';
import isInstagramStory from '@/posts/utils/isInstagramStory';
import checkInstagramImageStoryRecommendedAspectRatio from '@/posts/utils/checkInstagramImageStoryRecommendedAspectRatio';
import checkInstagramImageStorySize from '@/posts/utils/checkInstagramImageStorySize';
import isTiktokChannelDirectPublishing from '@/posts/utils/isTiktokChannelDirectPublishing';
import getPostTiktokVideoMaxDuration from '@/posts/utils/getPostTiktokVideoMaxDuration';
import checkLinkedinDocumentSize from '@/posts/utils/checkLinkedinDocumentSize';
import checkLinkedinDocumentPages from '@/posts/utils/checkLinkedinDocumentPages';
import getPostTiktokVideoOptions from '@/posts/utils/getPostTiktokVideoOptions';
import getTwitterMentionLimit from '@/posts/utils/getTwitterMentionLimit';
import getPostIncludedAttachments from '@/posts/utils/getPostIncludedAttachments';
import getMaxChannelAttachments from '@/posts/utils/getMaxChannelAttachments';
import getTailoredImageUrl from '@/posts/utils/getTailoredImageUrl';
import checkThreadsImageAspectRatio from '@/posts/utils/checkThreadsImageAspectRatio';
import checkThreadsImageSize from '@/posts/utils/checkThreadsImageSize';
import checkThreadsVideoSize from '@/posts/utils/checkThreadsVideoSize';
import checkThreadsVideoResolution from '@/posts/utils/checkThreadsVideoResolution';
import checkThreadsDuration from '@/posts/utils/checkThreadsDuration';
import checkThreadsVideoAspectRatio from '@/posts/utils/checkThreadsVideoAspectRatio';

const ID_FEEDBACK_MESSAGE_MAX_REACHED_ATTACHMENTS = 'limit-reached-attachments';

const facebookMessagesRepository = {
  videoReelsRatio: {
    channel_type: CHANNEL_FACEBOOK,
    level: 'error',
    text: `Facebook requires reels to have an aspect ratio of 9:16.`,
  },
  videoReelsLength: {
    channel_type: CHANNEL_FACEBOOK,
    level: 'error',
    text: `Facebook requires reels to be between 3 and 90 seconds.`,
  },
  videoReelsResolution: {
    channel_type: CHANNEL_FACEBOOK,
    level: 'error',
    text: `Facebook requires reels to be at least 540 x 960 pixels.`,
  },
  document: {
    channel_type: CHANNEL_FACEBOOK,
    level: 'error',
    text: `Facebook does not allow document attachments through the API. Please select a different attachment or remove the Facebook destination.`,
  },
};

const instagramMessagesRepository = {
  linkCustomization: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'info',
    text: 'Instagram does not allow link posts through the API. StoryChief will fallback to posting a thumbnail of the link.',
  },
  imageRatio(type, attachableUrl) {
    return {
      channel_type: CHANNEL_INSTAGRAM,
      level: 'error',
      type,
      text: `Instagram requires images to have an aspect ratio between 4:5 and 1:91:1.`,
      attachableUrl,
    };
  },
  videoRatio: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram requires videos to have an aspect ratio between 4:5 and 16:9.`,
  },
  videoReelsRatio: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram requires reels to have an aspect ratio between 0.01:1 and 10:1.`,
  },
  videoLength: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram requires videos to be between 3 and 60 seconds long.`,
  },
  videoResolution: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram requires videos to be less than 1920px wide.`,
  },
  videoReelsResolution: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram requires reels to be less than 1920px wide.`,
  },
  videoReelsLength: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram requires reels to be between 3 seconds and 15 minutes long.`,
  },
  reelsSize: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram requires reels to be less than 1GB.`,
  },
  noImage: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `No image found to publish to Instagram. Add an image by clicking modify preview on the Instagram post.`,
  },
  videoStoriesLength: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram requires stories to be between 3 seconds and 60 seconds long.`,
  },
  videoStoriesRatio: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram requires stories to have an aspect ratio between 0.01:1 and 10:1.`,
  },
  videoStoriesSize: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram requires stories to be less than 100MB.`,
  },
  videoStoriesResolution: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram requires stories to be less than 1920px wide.`,
  },
  imageStoriesRatio: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'info',
    text: `Instagram recommends stories to have an aspect ratio of 9:16.`,
  },
  imageStoriesSize: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram requires stories to be less than 8MB.`,
  },
  document: {
    channel_type: CHANNEL_INSTAGRAM,
    level: 'error',
    text: `Instagram does not allow document attachments through the API. Please select a different attachment or remove the Instagram destination.`,
  },
  noPublishScope(destinationId) {
    return {
      channel_type: CHANNEL_INSTAGRAM,
      level: 'error',
      text: `Direct scheduling is required to be enabled to publish to Instagram. Activate it by <a href="${StoryChief.dashboardBasePath}/channels/instagram/edit/${destinationId}">reconnecting your Instagram channel</a>.`,
    };
  },
};

const linkedinMessagesRepository = {
  noPageFollowersScope(destination) {
    return {
      channel_type: CHANNEL_LINKEDIN,
      level: 'info',
      text: `You can now mention your page followers 🎉. <a href="${destination.auth_url}?redirect_url=${window.location.href}">Reconnect</a>`,
    };
  },
  linkImageNotValid: {
    channel_type: CHANNEL_LINKEDIN,
    level: 'error',
    text: `The link uses an image type that is unsupported by LinkedIn. Modify the image to proceed.`,
  },
  documentSize: {
    channel_type: CHANNEL_LINKEDIN,
    level: 'error',
    text: `Linkedin does not allow uploading documents larger than 100MB`,
  },
  numberOfPages: {
    channel_type: CHANNEL_LINKEDIN,
    level: 'error',
    text: `Linkedin does not allow uploading documents with more than 300 pages`,
  },
};

const pinterestMessagesRepository = {
  noImage: {
    channel_type: CHANNEL_PINTEREST,
    level: 'error',
    text: `No image found to publish to Pinterest. Add an image by clicking modify preview on the Pinterest post.`,
  },
  video: {
    level: 'error',
    text: `Pinterest does not allow video posts through the API. Please select a different attachment or remove the Pinterest destination.`,
  },
  document: {
    level: 'error',
    text: `Pinterest does not allow document posts through the API. Please select a different attachment or remove the Pinterest destination.`,
  },
  noAttachment: {
    level: 'error',
    text: 'Pinterest does not allow text only posts. Please select an attachment.',
  },
};

const threadsMessagesRepository = {
  imageSize: {
    channel_type: CHANNEL_THREADS,
    level: 'error',
    type: 'image',
    text: `threads requires images to be between 320px and 1440px in width.`,
  },
  imageRatio: {
    channel_type: CHANNEL_THREADS,
    level: 'info',
    type: 'Image',
    text: `Threads recommends images to have an aspect ratio of 1:10`,
  },
  imageAspectRatio(attachableUrl) {
    return {
      channel_type: CHANNEL_THREADS,
      level: 'error',
      type: 'image',
      text: `threads requires images to have an aspect ratio between 10:1 and 10:1.`,
      attachableUrl,
    };
  },
  videoSize: {
    channel_type: CHANNEL_THREADS,
    level: 'error',
    text: `Threads requires videos to be less than 1GB.`,
  },
  videoResolution: {
    channel_type: CHANNEL_THREADS,
    level: 'error',
    text: `threads requires videos to be less than 1920px wide.`,
  },
  videoDuration: {
    channel_type: CHANNEL_THREADS,
    level: 'error',
    text: `threads requires videos to be between 1 second and 5 minutes long.`,
  },
  videoAspectRatio: {
    channel_type: CHANNEL_THREADS,
    level: 'error',
    text: `Required aspect ratio is between 0.01:1 and 10:1 but we recommend 9:16 to avoid cropping or blank space.`,
  },
  noImage: {
    channel_type: CHANNEL_THREADS,
    level: 'error',
    text: `No image found to publish to threads. Add an image by clicking modify preview on the threads post.`,
  },
  document: {
    channel_type: CHANNEL_THREADS,
    level: 'error',
    text: `threads does not allow document attachments through the API. Please select a different attachment or remove the threads destination.`,
  },
};

export default class PostFeedbackMessages {
  constructor(post) {
    this.feedbackMessages = [];
    this.post = post;
    this.attachmentMedia = getPostAttachmentMedia(post);
    this.includedAttachments = getPostIncludedAttachments(post);
    this.attachmentType =
      post.postset && !!post.postset.attachments.length
        ? getPostsetAttachmentsType(this.includedAttachments)
        : null;
    this.imageLoaders = [];
  }

  setLimitReachedMessage() {
    const messageLimitReached = getPostMessageLimitReached({
      post: this.post,
      postset: this.post.__typename === 'ReferralOutreach' ? this.post.shared : this.post.postset,
    });
    if (messageLimitReached) {
      this.feedbackMessages.push({
        level: 'error',
        text: `Message is too long. Please shorten it before publishing.`,
      });
    }
  }

  setLimitReachedAttachments() {
    const maxAttachments = getMaxChannelAttachments(this.post, this.includedAttachments);

    if (this.includedAttachments.length > maxAttachments) {
      this.feedbackMessages.push({
        level: 'error',
        id: ID_FEEDBACK_MESSAGE_MAX_REACHED_ATTACHMENTS,
        text: `Maximum number of ${maxAttachments} attachments reached. Please remove some before publishing.`,
      });
    }
  }

  setLimitReachedHashtag() {
    const hashtagLimitReached = getHashtagLimitReached({
      post: this.post,
      postset: this.post.__typename === 'ReferralOutreach' ? this.post.shared : this.post.postset,
    });

    const destination = this.post.destination;
    const { channel_type } = destination;
    const isFacebookChannel = channel_type === CHANNEL_FACEBOOK;

    if (hashtagLimitReached) {
      this.feedbackMessages.push({
        level: isFacebookChannel ? 'info' : 'error',
        text: isFacebookChannel
          ? `Facebook highlights only the first ${FACEBOOK_HASHTAGS_LIMIT} hashtags. Additional hashtags will appear as normal text.`
          : `Maximum number of ${getDestinationHashtagLimit(
              destination,
            )} hashtags reached. Please remove some before publishing.`,
      });
    }
  }

  setLimitReachedTitle() {
    const limit = getDestinationTitleLimit(this.post.destination.channel_type);

    if (limit !== null && this.post.title?.length > limit) {
      this.feedbackMessages.push({
        level: 'error',
        text: `Title is too long. Please shorten it before publishing.`,
      });
    }
  }

  setMaxSizeMessage({ image, channelType, max }) {
    if (image.naturalWidth > max.width || image.naturalHeight > max.height) {
      const hasChannelMessage = this.feedbackMessages.some(
        (m) =>
          m.channel_type === channelType && m.type === 'maxSize' && m.attachableUrl === image.src,
      );

      if (!hasChannelMessage) {
        this.feedbackMessages.push({
          channel_type: channelType,
          level: 'error',
          type: this.attachmentType === 'Story' ? 'maxSizeStoryOrLink' : 'maxSize',
          text: `The image cannot be larger than ${max.width} x ${max.height} px.`,
          attachableUrl: image.src,
        });
      }
    }
  }

  setRecommendedMinSizeMessage({ image, channelType, size }) {
    if (image.naturalWidth < size.width || image.naturalHeight < size.height) {
      const hasChannelMessage = this.feedbackMessages.some(
        (m) => m.channel_type === channelType && m.type === 'recommendedSize',
      );

      if (!hasChannelMessage) {
        this.feedbackMessages.push({
          channel_type: channelType,
          level: 'info',
          type: 'recommendedSize',
          text: `The recommended minimum image size is ${size.width} x ${size.height} px.`,
          attachableUrl: image.src,
        });
      }
    }
  }

  setImageSizeMessages(image, sizeName) {
    if (SOCIAL_MAX_SIZES[sizeName]) {
      this.setMaxSizeMessage({
        image,
        channelType: this.post.destination.channel_type,
        max: SOCIAL_MAX_SIZES[sizeName],
      });
    }

    if (SOCIAL_RECOMMENDED_MIN_SIZES[sizeName]) {
      this.setRecommendedMinSizeMessage({
        image,
        channelType: this.post.destination.channel_type,
        size: SOCIAL_RECOMMENDED_MIN_SIZES[sizeName],
      });
    }
  }

  setAmbassadorsMessages() {
    if (!this.post.subject) {
      this.feedbackMessages.push({
        level: 'error',
        text: `Subject is required.`,
      });
    }
  }

  setLinkedInMessages() {
    if (this.attachmentMedia) {
      if (this.attachmentType === 'Image') {
        this.attachmentMedia.forEach((attachmentMediaItem) => {
          const imageLoader = loadImage(attachmentMediaItem.url).then((image) => {
            this.setImageSizeMessages(image, 'linkedin');
          });
          this.imageLoaders.push(imageLoader);
        });
      } else if (this.attachmentType === 'Story') {
        const imageLoader = loadImage(this.attachmentMedia.url).then((image) => {
          this.setImageSizeMessages(image, 'linkedin');
        });
        this.imageLoaders.push(imageLoader);
      } else if (this.attachmentType === 'Link') {
        // Check if the URL matches the image extension regex and is not a webp format
        const imageExtensionRegex = /\.(jpe?g|png)(\?|#|$)/i;
        const url = getLinkAttachmentImageUrl(this.attachmentMedia.url);

        if (!imageExtensionRegex.test(url)) {
          this.feedbackMessages.push(linkedinMessagesRepository.linkImageNotValid);
        }
      } else if (this.attachmentType === 'Document') {
        const { file_size, number_of_pages } = this.post.postset.attachments[0].attachable;
        const sizeAllowed = checkLinkedinDocumentSize(file_size);
        const pagesAllowed = checkLinkedinDocumentPages(number_of_pages);
        if (!sizeAllowed) {
          this.feedbackMessages.push(linkedinMessagesRepository.documentSize);
        }
        if (!pagesAllowed) {
          this.feedbackMessages.push(linkedinMessagesRepository.numberOfPages);
        }
      } else if (this.attachmentType === 'Video' && this.includedAttachments.length > 1) {
        this.feedbackMessages = [
          {
            level: 'error',
            text: 'LinkedIn does not allow publishing multiple videos in one post.',
          },
        ];
      } else if (this.attachmentType === 'Media') {
        this.feedbackMessages = [
          {
            level: 'error',
            text: 'LinkedIn does not allow publishing videos and images together in one post.',
          },
        ];
      }
    }
  }

  setLinkedInCompanyMessages() {
    this.setLinkedInMessages();
    const hasPageFollowerScope = getLinkedInDestinationHasPageFollowersScope(this.post.destination);
    if (!hasPageFollowerScope) {
      this.feedbackMessages.push(
        linkedinMessagesRepository.noPageFollowersScope(this.post.destination),
      );
    }
  }

  setLinkedInProfileMessages() {
    this.setLinkedInMessages();
  }

  setTwitterMessages() {
    const message =
      JSON.parse(this.post.message || this.post.postset.message)?.blocks?.[0]?.text || '';
    const mentionLimit = getTwitterMentionLimit();
    if (message.match(/\B@\w+/gi)?.length > mentionLimit.value) {
      this.feedbackMessages.push({
        level: 'error',
        text: mentionLimit.errorMessage,
      });
    }

    if (this.attachmentType === 'Video' || this.attachmentType === 'Media') {
      this.includedAttachments
        .filter(({ attachable }) => attachable.__typename === 'Video')
        .forEach(async (attachment) => {
          const videoSpecsNotMet = getTwitterVideoLimitErrors(attachment.attachable);
          if (videoSpecsNotMet.length) {
            const videoSpecsNotMetString = videoSpecsNotMet.join(', ');

            if (this.includedAttachments.length > 1) {
              this.feedbackMessages.push({
                level: 'error',
                text: `Due to X (Twitter) limitations, videos with ${videoSpecsNotMetString} cannot be uploaded when uploading multiple attachments. Please unselect it before publishing.`,
              });
            } else {
              this.feedbackMessages.push({
                level: 'info',
                text: `Due to X (Twitter) limitations, videos with ${videoSpecsNotMetString} are not added natively. A video embed will be used. <a class="link-reset text-muted text-underline" target="_blank" href="https://help.storychief.io/en/articles/4149836-embed-videos-inside-social-posts#video-types">Learn more</a>`,
              });
            }
          }
        });
    }

    if (this.attachmentType === 'Image' && this.attachmentMedia) {
      this.attachmentMedia.forEach((attachmentMediaItem) => {
        const imageLoader = loadImage(attachmentMediaItem.url).then((image) => {
          this.setImageSizeMessages(image, 'twitter');
        });
        this.imageLoaders.push(imageLoader);
      });
    }

    if (this.attachmentType === 'Document' && this.attachmentMedia) {
      this.feedbackMessages.push({
        level: 'error',
        text: `X (Twitter) does not allow document attachments through the API. Please select a different attachment or remove the X (Twitter) destination.`,
      });
    }
  }

  async setInstagramMessages() {
    const hasPublishScope = getInstagramDestinationHasPublishScope(this.post.destination);
    switch (this.attachmentType) {
      case 'Story':
      case 'Link': {
        this.feedbackMessages.push(instagramMessagesRepository.linkCustomization);
        if (!hasPublishScope) {
          this.feedbackMessages.push(
            instagramMessagesRepository.noPublishScope(this.post.destination.id),
          );
        }
        if (this.attachmentMedia && this.attachmentMedia.url) {
          const imageLoader = loadImage(this.attachmentMedia.url).then(
            (image) => {
              const ratioAllowed = checkInstagramImageAspectRatio(
                image.naturalWidth,
                image.naturalHeight,
              );
              if (!ratioAllowed) {
                this.feedbackMessages.push(
                  instagramMessagesRepository.imageRatio('aspectRatioStoryOrLink', image.src),
                );
              }
            },
            () => this.feedbackMessages.push(instagramMessagesRepository.noImage),
          );
          this.imageLoaders.push(imageLoader);
        } else {
          this.feedbackMessages.push(instagramMessagesRepository.noImage);
        }
        break;
      }
      case 'Media':
      case 'Video':
      case 'Image': {
        if (this.attachmentMedia) {
          this.includedAttachments.forEach(async (attachment, index) => {
            if (attachment.attachable.__typename === 'Image') {
              const { url, file_size } = this.attachmentMedia[index];

              const imageLoader = loadImage(url).then(
                (image) => {
                  if (isInstagramStory(this.post)) {
                    const ratioRecommended = checkInstagramImageStoryRecommendedAspectRatio(
                      image.naturalWidth,
                      image.naturalHeight,
                    );
                    const sizeStoriesAllowed = checkInstagramImageStorySize(file_size);

                    if (!ratioRecommended) {
                      this.feedbackMessages.push(instagramMessagesRepository.imageStoriesRatio);
                    }

                    if (!sizeStoriesAllowed) {
                      this.feedbackMessages.push(instagramMessagesRepository.imageStoriesSize);
                    }
                  } else {
                    const ratioAllowed = checkInstagramImageAspectRatio(
                      image.naturalWidth,
                      image.naturalHeight,
                    );
                    if (!ratioAllowed) {
                      this.feedbackMessages.push(
                        instagramMessagesRepository.imageRatio('aspectRatioImage', image.src),
                      );
                    }

                    this.setImageSizeMessages(image, 'instagram');
                  }
                },
                () => this.feedbackMessages.push(instagramMessagesRepository.noImage),
              );
              this.imageLoaders.push(imageLoader);
            } else {
              const { width, height, file_size } = attachment.attachable;
              const videoLength = await getPostsetVideoAttachmentLength(attachment.attachable);
              const videoOptions = getPostInstagramVideoOptions(this.post);
              const videoType = videoOptions.type.value;
              // Ratio
              const ratioReelsAllowed = checkInstagramReelsAspectRatio({
                width,
                height,
                type: videoType,
              });
              const ratioStoriesVideoAllowed = checkInstagramVideoStoryAspectRatio({
                width,
                height,
                type: videoType,
              });
              // Duration
              const durationReelsAllowed = checkInstagramReelsDuration(videoLength, videoType);
              const durationStoriesAllowed = checkInstagramVideoStoryDuration(
                videoLength,
                videoType,
              );
              // Size
              const sizeReelsAllowed = checkInstagramReelsSize(file_size, videoType);
              const sizeStoriesAllowed = checkInstagramVideoStorySize(file_size, videoType);
              // Resolution
              const resolutionReelsAllowed = checkInstagramReelsResolution(width, videoType);
              const resolutionStoriesAllowed = checkInstagramVideoStoryResolution(width, videoType);

              if (!durationReelsAllowed || !durationStoriesAllowed) {
                if (!durationReelsAllowed) {
                  this.feedbackMessages.push(instagramMessagesRepository.videoReelsLength);
                }

                if (!durationStoriesAllowed) {
                  this.feedbackMessages.push(instagramMessagesRepository.videoStoriesLength);
                }
              } else if (!ratioReelsAllowed || !ratioStoriesVideoAllowed) {
                if (!ratioReelsAllowed) {
                  this.feedbackMessages.push(instagramMessagesRepository.videoReelsRatio);
                }

                if (!ratioStoriesVideoAllowed) {
                  this.feedbackMessages.push(instagramMessagesRepository.videoStoriesRatio);
                }
              } else if (!hasPublishScope) {
                this.feedbackMessages.push(
                  instagramMessagesRepository.noPublishScope(this.post.destination.id),
                );
              } else if (!sizeReelsAllowed || !sizeStoriesAllowed) {
                if (!sizeReelsAllowed) {
                  this.feedbackMessages.push(instagramMessagesRepository.reelsSize);
                }

                if (!sizeStoriesAllowed) {
                  this.feedbackMessages.push(instagramMessagesRepository.videoStoriesSize);
                }
              } else if (!resolutionReelsAllowed || !resolutionStoriesAllowed) {
                if (!resolutionReelsAllowed) {
                  this.feedbackMessages.push(instagramMessagesRepository.videoReelsResolution);
                }

                if (!resolutionStoriesAllowed) {
                  this.feedbackMessages.push(instagramMessagesRepository.videoStoriesResolution);
                }
              }
            }
          });
        }
        break;
      }
      case 'document':
        this.feedbackMessages.push(instagramMessagesRepository.document);
        break;
      default:
        this.feedbackMessages.push({
          type: 'text-only',
          channel_type: CHANNEL_INSTAGRAM,
          level: 'error',
          text: 'Instagram does not allow text only posts. Please select an attachment.',
        });
        break;
    }
  }

  setFacebookMessages() {
    if (this.attachmentType === 'Image' && this.attachmentMedia) {
      this.includedAttachments.forEach((_, index) => {
        const { url } = this.attachmentMedia[index];
        const imageLoader = loadImage(url).then((image) => {
          this.setImageSizeMessages(image, 'facebook');
        });
        this.imageLoaders.push(imageLoader);
      });
    } else if (this.attachmentType === 'Media' && this.attachmentMedia) {
      this.feedbackMessages = [
        {
          level: 'error',
          text: 'Facebook does not allow publishing videos and images together in one post.',
        },
      ];
    } else if (this.attachmentType === 'Document' && this.attachmentMedia) {
      this.feedbackMessages.push(facebookMessagesRepository.document);
    }
  }

  async setFacebookPageReelsMessages() {
    if (this.attachmentType === 'Video') {
      const videoOptions = getPostFacebookVideoOptions(this.post);

      if (this.includedAttachments.length > 1) {
        this.feedbackMessages = [
          {
            level: 'error',
            text: 'Facebook does not allow publishing multiple videos in one post.',
          },
        ];
      } else if (videoOptions.type.value === FACEBOOK_VIDEO_TYPES.reel.value) {
        /* {@link https://developers.facebook.com/docs/video-api/guides/reels-publishing/#reels-publishing-api Requirements } */
        const { width, height } = this.post.postset.attachments[0].attachable;
        const videoLength = await getPostsetVideoAttachmentLength(
          this.post.postset.attachments[0].attachable,
        );
        const videoType = videoOptions.type.value;
        const ratioReelsAllowed = checkFacebookReelsAspectRatio({
          width,
          height,
          type: videoType,
        });
        const durationReelsAllowed = checkFacebookReelsDuration(videoLength, videoType);
        const resolutionReelsAllowed = checkFacebookVideoResolution(width, height, videoType);

        if (!durationReelsAllowed) {
          this.feedbackMessages.push(facebookMessagesRepository.videoReelsLength);
        } else if (!ratioReelsAllowed) {
          this.feedbackMessages.push(facebookMessagesRepository.videoReelsRatio);
        } else if (!resolutionReelsAllowed) {
          this.feedbackMessages.push(facebookMessagesRepository.videoReelsResolution);
        }
      }
    }
  }

  async setTikTokMessages() {
    const isDirectPublishing = isTiktokChannelDirectPublishing(this.post.destination);
    if (!isDirectPublishing) {
      this.feedbackMessages.push({
        type: 'direct-publishing',
        channel_type: CHANNEL_TIKTOK,
        level: 'error',
        text: `Direct publishing is now available 🎉. <a href="${this.post.destination.auth_url}?redirect_url=${window.location.href}">Reconnect</a> to enable.`,
      });
    }
    switch (this.attachmentType) {
      case 'Video': {
        const [attachment] = this.post.postset.attachments;
        const videoLength = await getPostsetVideoAttachmentLength(
          this.post.postset.attachments[0].attachable,
        );

        if (attachment.attachable.file_size / 1_000_000 > 250) {
          this.feedbackMessages.push({
            type: 'file-size',
            channel_type: CHANNEL_TIKTOK,
            level: 'error',
            text: 'The video file size must be less 250 MB.',
          });
        }
        // keep this condition to avoid unneeded errors for existing connections.
        // Can be removed when everyone is migrated.
        if (isDirectPublishing) {
          const postOptions = getPostTiktokVideoOptions(this.post);

          if (
            videoLength < 3 ||
            videoLength > getPostTiktokVideoMaxDuration(this.post.destination)
          ) {
            this.feedbackMessages.push({
              type: 'duration',
              channel_type: CHANNEL_TIKTOK,
              level: 'error',
              text: `The duration of the video must be between 3 seconds and ${getPostTiktokVideoMaxDuration(
                this.post.destination,
              )} seconds.`,
            });
          }

          if (!postOptions || !postOptions.privacy) {
            this.feedbackMessages.push({
              type: 'privacy',
              channel_type: CHANNEL_TIKTOK,
              level: 'error',
              text: 'Please select a privacy option.',
            });
          }

          if (
            postOptions?.promotes?.is_promotion &&
            !(postOptions.promotes?.brand_organic || postOptions.promotes?.brand_content)
          ) {
            this.feedbackMessages.push({
              type: 'privacy',
              channel_type: CHANNEL_TIKTOK,
              level: 'error',
              text: 'Please indicate whether you are promoting your own business, a third party business, or both.',
            });
          }
        } else if (videoLength < 3 || videoLength > 60) {
          this.feedbackMessages.push({
            type: 'length',
            channel_type: CHANNEL_TIKTOK,
            level: 'error',
            text: 'The duration of the video must be between 3 seconds and 60 seconds.',
          });
        }

        if (
          (attachment.attachable.width < 540 && attachment.attachable.height < 960) ||
          (attachment.attachable.width < 960 && attachment.attachable.height < 540)
        ) {
          this.feedbackMessages.push({
            type: 'min-size',
            channel_type: CHANNEL_TIKTOK,
            level: 'error',
            text: "Due to TikTok limitations, we can't post videos to smaller than 540p (540×960 pixels).",
          });
        }

        break;
      }
      case 'Media':
      case 'Image':
      case 'Story':
      case 'Link':
      case 'Document':
      default:
        this.feedbackMessages.push({
          type: 'attachment',
          channel_type: CHANNEL_TIKTOK,
          level: 'error',
          text: 'TikTok only supports video attachments.',
        });
        break;
    }
  }

  setSlackMessages() {
    this.includedAttachments
      .filter((a) => a.attachable.__typename === 'Image')
      .forEach((a) => {
        const url = getTailoredImageUrl(this.post, a);

        const imageLoader = loadImage(url).then((image) => {
          // Detect image size to small or to small
          this.setImageSizeMessages(image, 'slack');
        });
        this.imageLoaders.push(imageLoader);
      });

    if (this.includedAttachments.some((a) => a.attachable.__typename === 'Document')) {
      this.feedbackMessages.push({
        type: 'document',
        channel_type: CHANNEL_SLACK,
        level: 'error',
        text: 'Slack does not allow document attachments through the API. Please select a different attachment or remove the Slack destination.',
      });
    }
  }

  setGoogleBusinessMessages() {
    switch (this.attachmentType) {
      case 'Image':
        this.attachmentMedia.forEach((attachmentMediaItem) => {
          const imageLoader = loadImage(attachmentMediaItem.url).then((image) => {
            this.setImageSizeMessages(image, 'googleBusiness');
          });
          this.imageLoaders.push(imageLoader);
        });
        break;
      case 'Video':
        this.feedbackMessages.push({
          type: 'video',
          channel_type: CHANNEL_GOOGLE_BUSINESS,
          level: 'info',
          text: 'Google My Business does not allow video posts through the API. StoryChief will fallback to posting a thumbnail of the video.',
        });
        break;
      case 'Document':
        this.feedbackMessages.push({
          type: 'document',
          channel_type: CHANNEL_GOOGLE_BUSINESS,
          level: 'error',
          text: 'Google My Business does not allow document posts through the API. Please select a different attachment or remove the Google My Business destination.',
        });
        break;
      default:
        break;
    }
  }

  setPinterestMessages() {
    switch (this.attachmentType) {
      case 'Story':
      case 'Link': {
        if (this.attachmentMedia && this.attachmentMedia.url) {
          const imageLoader = loadImage(this.attachmentMedia.url).catch(() =>
            this.feedbackMessages.push(pinterestMessagesRepository.noImage),
          );
          this.imageLoaders.push(imageLoader);
        } else {
          this.feedbackMessages.push(pinterestMessagesRepository.noImage);
        }
        break;
      }
      case 'Image': {
        break;
      }
      case 'Document': {
        this.feedbackMessages.push(pinterestMessagesRepository.document);
        break;
      }
      case 'Media':
      case 'Video': {
        this.feedbackMessages.push(pinterestMessagesRepository.video);
        break;
      }
      default: {
        this.feedbackMessages.push(pinterestMessagesRepository.noAttachment);
        break;
      }
    }
  }

  async setThreadsMessages() {
    switch (this.attachmentType) {
      case 'Media':
      case 'Video':
      case 'Image': {
        this.includedAttachments.map(async (attachment) => {
          const { url, width, height, file_size } = attachment.attachable;

          if (attachment.attachable.__typename === 'Image') {
            const tailoredImageUrl = getTailoredImageUrl(this.post, attachment);

            const imageLoader = loadImage(tailoredImageUrl).then(
              (image) => {
                const aspectRatioAllowed = checkThreadsImageAspectRatio(
                  image.naturalWidth,
                  image.naturalHeight,
                );

                if (!aspectRatioAllowed) {
                  this.feedbackMessages.push(threadsMessagesRepository.imageAspectRatio(url));
                }

                this.setImageSizeMessages(image, 'threads');
              },
              () => this.feedbackMessages.push(threadsMessagesRepository.noImage),
            );
            this.imageLoaders.push(imageLoader);

            const sizeAllowed = checkThreadsImageSize(file_size);
            const ratioAllowed = checkThreadsImageAspectRatio(width, height);

            if (!sizeAllowed) {
              this.feedbackMessages.push(threadsMessagesRepository.imageSize);
            }

            if (!ratioAllowed) {
              this.feedbackMessages.push(threadsMessagesRepository.imageRatio);
            }
          }

          if (attachment.attachable.__typename === 'Video') {
            const videoLength = await getPostsetVideoAttachmentLength(attachment.attachable);
            const sizeAllowed = checkThreadsVideoSize(file_size);
            const resolutionAllowed = checkThreadsVideoResolution(width);
            const durationAllowed = checkThreadsDuration(videoLength);
            const aspectRatioAllowed = checkThreadsVideoAspectRatio(width, height);

            if (!sizeAllowed) {
              this.feedbackMessages.push(threadsMessagesRepository.videoSize);
            }

            if (!resolutionAllowed) {
              this.feedbackMessages.push(threadsMessagesRepository.videoResolution);
            }

            if (!durationAllowed) {
              this.feedbackMessages.push(threadsMessagesRepository.videoDuration);
            }

            if (!aspectRatioAllowed) {
              this.feedbackMessages.push(threadsMessagesRepository.videoAspectRatio);
            }
          }
        });
        break;
      }
      case 'Document': {
        this.feedbackMessages.push(threadsMessagesRepository.document);
        break;
      }
      default:
        break;
    }
  }

  async setMessages() {
    if (this.post && this.post.status === STATUS_DRAFT) {
      this.setLimitReachedMessage();
      this.setLimitReachedTitle();
      this.setLimitReachedHashtag();
      this.setLimitReachedAttachments();

      switch (this.post.destination.channel_type) {
        case CHANNEL_AMBASSADORS: {
          this.setAmbassadorsMessages();
          break;
        }
        case CHANNEL_LINKEDIN: {
          this.setLinkedInCompanyMessages();
          break;
        }
        case CHANNEL_LINKEDIN_PROFILE: {
          this.setLinkedInProfileMessages();
          break;
        }
        case CHANNEL_TWITTER: {
          this.setTwitterMessages();
          break;
        }
        case CHANNEL_INSTAGRAM: {
          await this.setInstagramMessages();
          break;
        }
        case CHANNEL_SLACK: {
          this.setSlackMessages();
          break;
        }
        case CHANNEL_GOOGLE_BUSINESS: {
          this.setGoogleBusinessMessages();
          break;
        }
        case CHANNEL_PINTEREST: {
          this.setPinterestMessages();
          break;
        }
        case CHANNEL_FACEBOOK: {
          this.setFacebookMessages();
          await this.setFacebookPageReelsMessages();
          break;
        }
        case CHANNEL_TIKTOK:
          await this.setTikTokMessages();
          break;
        case CHANNEL_THREADS:
          await this.setThreadsMessages();
          break;
        default:
          break;
      }
    }
  }

  async getMessages() {
    await this.setMessages();

    return new Promise((resolve) => {
      Promise.all(this.imageLoaders).then(() => {
        this.feedbackMessages = this.feedbackMessages.map((_message) => ({
          ..._message,
          object: this.post,
        }));
        resolve();
      });
    });
  }
}
