import StoryChief from '@/storychief';
import decodeHex from '@/storychief/utils/decodeHexString';

/**
 * @param {string} url
 * @returns {string}
 */
export default function getLinkAttachmentImageUrl(url) {
  if (url.includes(StoryChief.camoUrl)) {
    const parts = url.split('/');
    return decodeHex(parts[parts.length - 2]);
  }

  return url;
}
