import { CHANNEL_TIKTOK } from '@/destinations/constants/Constants';

/**
 * @param {object} destination
 * @returns {number}
 */
export default function getPostTiktokVideoMaxDuration(destination) {
  const info = destination.info ? JSON.parse(destination.info) : {};

  if ('creator_info' in info && destination.channel_type === CHANNEL_TIKTOK) {
    return info?.creator_info?.max_video_post_duration_sec ?? 0;
  }
  return 0;
}
