function getInstagramDestinationHasPublishScope(destination) {
  const { scopes } = destination.info ? JSON.parse(destination.info) : { scopes: null };
  return (
    scopes &&
    !!scopes.find(
      (_scope) => _scope.permission === 'instagram_content_publish' && _scope.status === 'granted',
    )
  );
}

export default getInstagramDestinationHasPublishScope;
