import { FACEBOOK_RESOLUTIONS, FACEBOOK_VIDEO_TYPES } from '@/posts/constants/Constants';

/**
 * @param {number} width
 * @param {number} height
 * @param {string} type
 * @returns {boolean}
 */
export default function checkFacebookVideoResolution(width, height, type) {
  if (type === FACEBOOK_VIDEO_TYPES.reel.value) {
    return width >= FACEBOOK_RESOLUTIONS.reels.width && height >= FACEBOOK_RESOLUTIONS.reels.height;
  }

  return true;
}
