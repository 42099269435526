import StoryChief from '@/storychief';

export const FEATURE_FLAGS = {
  editorColumnDivider: 'editorColumnDivider',
  allowExtraTwitterMentions: 'allowExtraTwitterMentions',
};

function hasColumnDividerFeature() {
  const telenetCorporateId = 43963;
  return StoryChief.account.id === telenetCorporateId && StoryChief.environment === 'production';
}

function hasExtraTwitterMentions() {
  const writeForMeAgencyId = 21119;
  return (
    (StoryChief.account.id === writeForMeAgencyId ||
      StoryChief.account.parent_id === writeForMeAgencyId) &&
    StoryChief.environment === 'production'
  );
}

/**
 * @param {string} feature
 * @returns {boolean}
 */
export function hasFeature(feature) {
  switch (feature) {
    case FEATURE_FLAGS.editorColumnDivider:
      return hasColumnDividerFeature();
    case FEATURE_FLAGS.allowExtraTwitterMentions:
      return hasExtraTwitterMentions();
    default:
      return false;
  }
}
