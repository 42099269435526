import getPostIncludedAttachments from '@/posts/utils/getPostIncludedAttachments';
import getPostsetAttachmentsType from '@/postsets/utils/getPostsetAttachmentsType';
import tryParseJson from '@/storychief/utils/tryParseJson';

function getPostAttachmentMediaImage(post, attachment) {
  const { caption, attachable } = attachment;
  const tailoredImage = post.tailored_images?.find((t) => t.attachable_id === attachable.id);
  const tailoredInfo =
    tailoredImage && tailoredImage.image_edits
      ? tryParseJson(tailoredImage?.image_edits).parsed
      : null;

  let fileSize = attachable.file_size;

  if (tailoredInfo && tailoredInfo.file_size) {
    fileSize = tailoredInfo.file_size;
  }

  return {
    id: attachable.id,
    url: tailoredImage?.image || attachable.url,
    caption: caption || null,
    file_size: fileSize,
  };
}

function getPostsetAttachmentMediaVideo(attachment) {
  return {
    url: attachment.attachable.url,
    thumb: attachment.attachable.thumbnail,
  };
}

function getPostAttachmentMedia(post) {
  const { attachments } = post.postset || {};

  switch (getPostsetAttachmentsType(attachments)) {
    case 'Story': {
      return {
        url:
          post.tailored_story?.image ||
          post.postset?.tailored_story?.image ||
          attachments[0].attachable.og_image,
        caption: null,
      };
    }
    case 'Link': {
      return {
        url:
          post.tailored_link?.image ||
          post.postset?.tailored_link?.image ||
          attachments[0].attachable.og_image,
        caption: null,
      };
    }
    case 'Image':
    case 'Video':
    case 'Media':
      return getPostIncludedAttachments(post).map((attachment) => {
        if (attachment.attachable.typename === 'Video') {
          return getPostsetAttachmentMediaVideo(attachment);
        }

        return getPostAttachmentMediaImage(post, attachment);
      });
    case 'Document':
      return {
        url: post.postset.attachments[0].attachable.url,
      };
    default:
      return null;
  }
}

export default getPostAttachmentMedia;
