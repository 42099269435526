import { FACEBOOK_VIDEO_TYPES } from '@/posts/constants/Constants';

/**
 * @param {object} post
 * @param {string} post.options
 * @returns {{type: {value: string, label: string}}}
 */
export default function getPostFacebookVideoOptions(post) {
  const postOptions = post.options ? JSON.parse(post.options) : null;
  const options = postOptions?.facebook_video || null;

  return {
    type: options?.type ? FACEBOOK_VIDEO_TYPES[options.type] : FACEBOOK_VIDEO_TYPES.video,
  };
}
