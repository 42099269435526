import { INSTAGRAM_VIDEO_SIZES, INSTAGRAM_VIDEO_TYPES } from '@/posts/constants/Constants';

/**
 * @param {number} size
 * @param {string} type
 * @returns {boolean}
 */
export default function checkInstagramReelsSize(size, type) {
  if (type === INSTAGRAM_VIDEO_TYPES.reel.value) {
    return size <= INSTAGRAM_VIDEO_SIZES.reels;
  }

  return true;
}
