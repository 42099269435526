import { THREADS_ASPECT_RATIO_LIMITS } from '@/posts/constants/Constants';

export default function checkThreadsImageAspectRatio(width: number, height: number): boolean {
  const ratio = width / height;

  return (
    ratio >= THREADS_ASPECT_RATIO_LIMITS.images.min &&
    ratio <= THREADS_ASPECT_RATIO_LIMITS.images.max
  );
}
