import { INSTAGRAM_ASPECT_RATIO_LIMITS } from '@/posts/constants/Constants';

/**
 * @param {number} width
 * @param {number} height
 * @returns {boolean}
 */
export default function checkInstagramImageStoryRecommendedAspectRatio(width, height) {
  const ratio = width / height;

  return ratio === INSTAGRAM_ASPECT_RATIO_LIMITS.stories.recommended;
}
