import { CHANNEL_PINTEREST, CHANNEL_TIKTOK } from '@/destinations/constants/Constants';

function getDestinationTitleLimit(channelType) {
  switch (channelType) {
    case CHANNEL_PINTEREST:
      return 100;
    case CHANNEL_TIKTOK:
      return 150;
    default:
      return null;
  }
}

export default getDestinationTitleLimit;
