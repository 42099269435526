import { CHANNEL_TIKTOK } from '@/destinations/constants/Constants';

/**
 * @param {object} destination
 * @returns {boolean}
 */
export default function isTiktokChannelDirectPublishing(destination) {
  const info = destination.info ? JSON.parse(destination.info) : {};

  if (destination.channel_type === CHANNEL_TIKTOK) {
    // We check this now until we are sure everyone has been migrated to direct publishing.
    return info?.enable_direct_publishing ?? false;
  }
  return false;
}
