import {
  CHANNEL_FACEBOOK,
  CHANNEL_TWITTER,
  CHANNEL_INSTAGRAM,
  CHANNEL_LINKEDIN,
  CHANNEL_LINKEDIN_PROFILE,
  CHANNEL_PINTEREST,
  CHANNEL_SLACK,
  CHANNEL_GOOGLE_BUSINESS,
  CHANNEL_TIKTOK,
  CHANNEL_THREADS,
} from '@/destinations/constants/Constants';
import isInstagramStory from '@/posts/utils/isInstagramStory';

export default function getMaxChannelAttachments(post, includedAttachments) {
  switch (post.destination.channel_type) {
    case CHANNEL_FACEBOOK:
      return includedAttachments.some((a) => a.attachable.__typename === 'Video') ? 1 : 10;
    case CHANNEL_TWITTER:
      return 4;
    case CHANNEL_INSTAGRAM:
      return isInstagramStory(post) ? 1 : 10;
    case CHANNEL_LINKEDIN:
    case CHANNEL_LINKEDIN_PROFILE:
      return includedAttachments.some((a) => a.attachable.__typename === 'Video') ? 1 : 9;
    case CHANNEL_TIKTOK:
      return 1;
    case CHANNEL_PINTEREST:
      return 1;
    case CHANNEL_SLACK:
      return 10;
    case CHANNEL_GOOGLE_BUSINESS:
      return 1;
    case CHANNEL_THREADS:
      return 10;
    default:
      return 10;
  }
}
