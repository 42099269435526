import { INSTAGRAM_VIDEO_TYPES, INSTAGRAM_RESOLUTIONS } from '@/posts/constants/Constants';

/**
 * @param {number} width
 * @param {string} type
 * @returns {boolean}
 */
export default function checkInstagramVideoStoryResolution(width, type) {
  if (type === INSTAGRAM_VIDEO_TYPES.story.value) {
    return width <= INSTAGRAM_RESOLUTIONS.stories;
  }

  return true;
}
