/**
 * @param {string} hexString
 * @returns {string}
 */
export default function decodeHex(hexString) {
  let decodedString = '';

  for (let n = 0; n < hexString.length; n += 2) {
    decodedString += String.fromCharCode(parseInt(hexString.substr(n, 2), 16));
  }

  return decodedString;
}
