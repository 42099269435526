import { FACEBOOK_ASPECT_RATIO_LIMITS, FACEBOOK_VIDEO_TYPES } from '@/posts/constants/Constants';

/**
 * @param {object} data
 * @param {number} data.width
 * @param {number} data.height
 * @param {string} data.type
 * @returns {boolean}
 */
export default function checkFacebookReelsAspectRatio({ width, height, type }) {
  const ratio = width / height;

  if (type === FACEBOOK_VIDEO_TYPES.reel.value) {
    return (
      ratio.toPrecision(2) >= FACEBOOK_ASPECT_RATIO_LIMITS.reels.min.toPrecision(2) &&
      ratio.toPrecision(2) <= FACEBOOK_ASPECT_RATIO_LIMITS.reels.max.toPrecision(2)
    );
  }

  return true;
}
