import { FACEBOOK_DURATION_LIMITS, FACEBOOK_VIDEO_TYPES } from '@/posts/constants/Constants';

/**
 * @param {number} duration
 * @param {string} type
 * @returns {boolean}
 */
export default function checkFacebookVideoDuration(duration, type) {
  if (type === FACEBOOK_VIDEO_TYPES.reel.value) {
    return (
      duration >= FACEBOOK_DURATION_LIMITS.reels.min &&
      duration <= FACEBOOK_DURATION_LIMITS.reels.max
    );
  }

  return true;
}
