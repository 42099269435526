/**
 * A temporary helper function that returns the video attachment length using the video DOM element instead of attachable.length.
 * This function is needed because attachable.length is an integer and rounded up to the nearest second. This causes issues with
 * the minimum video length requirement checks.
 * @param {string} postsetId
 * @returns {number}
 */

const videoAttachmentLengths = {};

export default function getPostsetVideoAttachmentLength(attachable) {
  return new Promise((resolve) => {
    if (videoAttachmentLengths[attachable.url]) {
      resolve(videoAttachmentLengths[attachable.url]);
      return;
    }

    const video = document.createElement('video');
    video.src = attachable.url;
    video.load();

    video.addEventListener('loadeddata', () => {
      resolve(video.duration);
      videoAttachmentLengths[attachable.url] = video.duration;
    });

    video.addEventListener('error', () => {
      resolve(attachable.length);
      videoAttachmentLengths[attachable.url] = attachable.length;
    });

    video.load();
  });
}
