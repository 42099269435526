import { CHANNEL_INSTAGRAM } from '@/destinations/constants/Constants';

/**
 * @param {object} object
 * @returns {boolean}
 */
export default function isInstagramStory(object) {
  if (object.destination.channel_type === CHANNEL_INSTAGRAM && object.options) {
    const { instagram_image, instagram_video, instagram_media } = JSON.parse(object.options);
    return (instagram_image?.type || instagram_video?.type || instagram_media?.type) === 'story';
  }

  return false;
}
