import { INSTAGRAM_ASPECT_RATIO_LIMITS, INSTAGRAM_VIDEO_TYPES } from '@/posts/constants/Constants';

/**
 * @param {{ width: number, height: number, type: string }} data
 * @returns {boolean}
 */
export default function checkInstagramVideoStoryAspectRatio({ width, height, type }) {
  const ratio = width / height;

  if (type === INSTAGRAM_VIDEO_TYPES.story.value) {
    return (
      ratio >= INSTAGRAM_ASPECT_RATIO_LIMITS.stories.min &&
      ratio <= INSTAGRAM_ASPECT_RATIO_LIMITS.stories.max
    );
  }

  return true;
}
