import { INSTAGRAM_DURATION_LIMITS, INSTAGRAM_VIDEO_TYPES } from '@/posts/constants/Constants';

/**
 * @param {number} duration
 * @param {string} type
 * @returns {boolean}
 */
export default function checkInstagramReelsDuration(duration, type) {
  if (type === INSTAGRAM_VIDEO_TYPES.reel.value) {
    return (
      duration >= INSTAGRAM_DURATION_LIMITS.reels.min &&
      duration <= INSTAGRAM_DURATION_LIMITS.reels.max
    );
  }

  return true;
}
