import { INSTAGRAM_VIDEO_TYPES } from '@/posts/constants/Constants';

/**
 * @param {object} post
 * @param {string} post.options
 * @returns {{type: {value: string, label: string}, share_to_feed: boolean}}
 */
export default function getPostInstagramVideoOptions(post) {
  const postOptions = post.options ? JSON.parse(post.options) : null;
  const options = postOptions?.instagram_video || null;

  if (!options || !Object.keys(INSTAGRAM_VIDEO_TYPES).includes(options.type)) {
    return {
      type: INSTAGRAM_VIDEO_TYPES.reel,
      share_to_feed: options ? options.share_to_feed : true,
    };
  }

  return {
    type: INSTAGRAM_VIDEO_TYPES[options.type],
    share_to_feed: !!options?.share_to_feed,
  };
}
