import getMaxChannelAttachments from '@/posts/utils/getMaxChannelAttachments';

/**
 * @param {object} post
 * @returns {Array}
 */
export default function getPostIncludedAttachments(post) {
  const { attachments } = post.postset || post.shared;
  if (post.excluded_attachables)
    return attachments.filter(
      (obj) =>
        !post.excluded_attachables.some(
          (a) => a.id === obj.attachable.id && a.type === `App\\${obj.attachable.__typename}`,
        ),
    );

  return attachments.slice(0, getMaxChannelAttachments(post, attachments));
}
